import React from "react"
import { graphql } from "gatsby"
import Accordion from "../components/accordion/accordion"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Wrapper from "../components/layout/wrapper"

const FaqPage = ({ data: { prismicFaqPage: { data } } }) => {

  return (
    <Layout>
      <SEO title="FAQ - Need help?" />
      <section className="page-hero">
          <Wrapper>
            <h1>How can we help you?</h1>
          </Wrapper>
        </section>
      <Wrapper>
      <section style={{marginTop: '1rem', marginBottom: '1rem'}}>
        <div style={{marginTop: '5rem'}}>
            <h3>{data.bookings_title.text}</h3>
            {data.booking_faqs.map(x => (
            <Accordion 
            title={x.question.text}
            content={x.answer.text}
            />
            ))}
        </div>
        <div style={{marginTop: '5rem'}}>
            <h3>{data.stay_title.text}</h3>
            {data.stay_faqs.map(x => (
            <Accordion 
            title={x.question.text}
            content={x.answer.text}
            />
            ))}
        </div>
        <div style={{marginTop: '5rem'}}>
            <h3>{data.payments_title.text}</h3>
            {data.payments_faqs.map(x => (
            <Accordion 
            title={x.question.text}
            content={x.answer.text}
            />
            ))}
        </div>
        <div style={{marginTop: '5rem'}}>
            <h3>{data.cancellations_title.text}</h3>
            {data.cancellations_faqs.map(x => (
            <Accordion 
            title={x.question.text}
            content={x.answer.text}
            />
            ))}
        </div>

      </section>
    </Wrapper>
    </Layout>
  )
}

export default FaqPage

export const query = graphql`
query FaqQuery {
    prismicFaqPage {
      data {
        bookings_title {
          text
        }
        booking_faqs {
          question {
            text
          }
          answer {
            text
          }
        }
        stay_title {
          text
        }
        stay_faqs {
          question {
            text
          }
          answer {
            text
          }
        }
        payments_title {
          text
        }
        payments_faqs {
          question {
            text
          }
          answer {
            text
          }
        }
        cancellations_title {
          text
        }
        cancellations_faqs {
          question {
            text
          }
          answer {
            text
          }
        }
      }
    }
  }
`
import React from "react";

function Chevron(props) {
  return (
    // <svg
    //   className={props.className}
    //   height={props.height}
    //   width={props.width}
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 320 512"
    // >
    //   <path
    //     fill={props.fill}
    //     d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
    //   />
    // </svg>

<svg 
className={props.className}
height={props.height}
width={props.width}
viewBox="0 0 36 11">
<g 
stroke="none" 
stroke-width="1" 
fill="none" 
fill-rule="evenodd"
>
<path d="M32.3685904,4.8125 L26.0322131,1.24828777 L25.5964254,1.00315715 L26.0866866,0.131581612 L26.5224744,0.376712231 L34.5224744,4.87671223 L35.2972082,5.3125 L34.5224744,5.74828777 L26.5224744,10.2482878 L26.0866866,10.4934184 L25.5964254,9.62184285 L26.0322131,9.37671223 L32.3685904,5.8125 L26.2773438,5.8125 L0.77734375,5.8125 L0.27734375,5.8125 L0.27734375,4.8125 L0.77734375,4.8125 L26.2773438,4.8125 L32.3685904,4.8125 Z" fill="currentColor" fill-rule="nonzero">
</path>
</g>
</svg>
  );
}

export default Chevron;